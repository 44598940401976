import {
    AddBox,
    Check,
    DeleteOutline,
    Edit,
    SaveAlt,
    FilterList,
    FirstPage,
    LastPage,
    ChevronRight,
    ChevronLeft,
    Clear,
    Search,
    ArrowDownward,
    Remove,
    ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";

export const LandingAdmin = [
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/poworkflow",
        menuName: "Production Order Workflow Status",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/bulkUploadPO",
        menuName: "Bulk Order & Production Order List",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/ackorder",
        menuName: "Acknowledge Production Orders",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/dispatchdetails",
        menuName: "Production Order Dispatch Details",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/grn",
        menuName: "GRN Production Orders",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/storeGRN",
        menuName: "Store GRN",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/reprintqr",
        menuName: "Re-print QR Code",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/admin",
        menuName: "Administrator",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/bufferlocation1",
        menuName: "Buffer Location1",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/testing",
        menuName: "Testing",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/bufferlocation2",
        menuName: "Buffer Location2",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/posttesting",
        menuName: "Post Testing",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/printing",
        menuName: "Printing",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/packing",
        menuName: "Packing",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/visual",
        menuName: "Visual",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/stagePD",
        menuName: "PD",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/storePD",
        menuName: "Store PD",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/stageBDC",
        menuName: "BDC",
    },
    {
        roleId: 1,
        role: "Administrator",
        menuLink: "/storeBDC",
        menuName: "Store BDC",
    },
];
const userData = window.localStorage.getItem("response");
const userObj = JSON.parse(userData);
export const userEmail = userObj ? userObj.userEmail : "";
export const userRole = userObj ? userObj.roleName : "";
export const userGid = userObj ? userObj.gid : "";
export const AdminstratorOptions = [
    {
        adminMenuId: 1,
        adminMenuName: "Stage Configuration",
        adminMenuLink: "/stageConfiguration",
    },
    {
        adminMenuId: 2,
        adminMenuName: "User Management",
        adminMenuLink: "/userList",
    },
    {
        adminMenuId: 3,
        adminMenuName: "Master Data",
        adminMenuLink: "/masterDetails",
    },
    {
        adminMenuId: 4,
        adminMenuName: "Reports",
        adminMenuLink: "/reports",
    },
];
export const LandingSupplier = [
    {
        roleId: 2,
        role: "Supplier",
        menuLink: "/poworkflow",
        menuName: "Production Order Workflow Status",
    },
    {
        roleId: 2,
        role: "Supplier",
        menuLink: "/ackorder",
        menuName: "Acknowledge Production Orders",
    },
    {
        roleId: 2,
        role: "Supplier",
        menuLink: "/reprintqr",
        menuName: "Re-print QR Code",
    },
    {
        roleId: 2,
        role: "Supplier",
        menuLink: "/dispatchdetails",
        menuName: "Production Order Dispatch Details",
    },
];
export const LandingLogistics = [
    {
        roleId: 3,
        role: "Logistics",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 3,
        role: "Logistics",
        menuLink: "/bulkUploadPO",
        menuName: "Bulk Order & Production Order List",
    },
    {
        roleId: 3,
        role: "Logistics",
        menuLink: "/poworkflow",
        menuName: "Production Order Workflow Status",
    },
    {
        roleId: 3,
        role: "Logistics",
        menuLink: "/reprintqr",
        menuName: "Re-print QR Code",
    },
    {
        roleId: 3,
        role: "Logistics",
        menuLink: "/stageConfiguration",
        menuName: "Stage Configuration",
    },
    {
        roleId: 3,
        role: "Logistics",
        menuLink: "/reports",
        menuName: "Reports",
    },
];
export const LandingStoreGRN = [
    {
        roleId: 4,
        role: "StoreGRN",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 4,
        role: "StoreGRN",
        menuLink: "/poworkflow",
        menuName: "Production Order Workflow Status",
    },
    {
        roleId: 4,
        role: "StoreGRN",
        menuLink: "/poList",
        menuName: "Production Order List",
    },
    {
        roleId: 4,
        role: "StageGRN",
        menuLink: "/grn",
        menuName: "GRN Production Orders",
    },
    {
        roleId: 4,
        role: "StoreGRN",
        menuLink: "/reports",
        menuName: "Reports",
    },
];
export const LandingOutSourcing = [
    {
        roleId: 5,
        role: "OutSourcing",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 5,
        role: "OutSourcing",
        menuLink: "/poworkflow",
        menuName: "Production Order Workflow Status",
    },
    {
        roleId: 5,
        role: "OutSourcing",
        menuLink: "/poList",
        menuName: "Production Order List",
    },
    {
        roleId: 5,
        role: "OutSourcing",
        menuLink: "/reports",
        menuName: "Reports",
    },
];
export const LandingProduction = [
    {
        roleId: 19,
        role: "Production",
        menuLink: "/poworkflow",
        menuName: "Production Order Workflow Status",
    },
    {
        roleId: 19,
        role: "Production",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 19,
        role: "Production",
        menuLink: "/poList",
        menuName: "Production Order List",
    },
    {
        roleId: 19,
        role: "Production",
        menuLink: "/reports",
        menuName: "Reports",
    },
];
export const LandingQA = [
    {
        roleId: 7,
        role: "QA",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 7,
        role: "QA",
        menuLink: "/poworkflow",
        menuName: "Production Order Workflow Status",
    },
    {
        roleId: 7,
        role: "QA",
        menuLink: "/poList",
        menuName: "Production Order List",
    },
    {
        roleId: 7,
        role: "QA",
        menuLink: "/reports",
        menuName: "Reports",
    },
];
export const LandingBDC = [
    {
        roleId: 8,
        role: "BDC",
        menuLink: "/poworkflow",
        menuName: "Production Order Workflow Status",
    },
    {
        roleId: 8,
        role: "BDC",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 8,
        role: "BDC",
        menuLink: "/poList",
        menuName: "Production Order List",
    },
    {
        roleId: 8,
        role: "BDC",
        menuLink: "/reports",
        menuName: "Reports",
    },
];
export const LandingStageGRN = [
    {
        roleId: 9,
        role: "StageGRN",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 9,
        role: "StageGRN",
        menuLink: "/grn",
        menuName: "Scan GRN Production Orders",
    },
];
export const LandingStageBL1 = [
    {
        roleId: 10,
        role: "StageBL1",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 10,
        role: "StageBL1",
        menuLink: "/bufferlocation1",
        menuName: "Scan Buffer Location1 Production Orders",
    },
];
export const LandingStageTesting = [
    {
        roleId: 11,
        role: "StageTesting",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 11,
        role: "StageTesting",
        menuLink: "/testing",
        menuName: "Scan Testing Production Orders",
    },
];
export const LandingStageBL2 = [
    {
        roleId: 12,
        role: "StageBL2",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 12,
        role: "StageBL2",
        menuLink: "/bufferlocation2",
        menuName: "Scan Buffer Location2 Production Orders",
    },
];
export const LandingStagePostTesting = [
    {
        roleId: 13,
        role: "StagePostTesting",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 13,
        role: "StagePostTesting",
        menuLink: "/posttesting",
        menuName: "Scan Post Testing Production Orders",
    },
];
export const LandingStagePacking = [
    {
        roleId: 14,
        role: "StagePacking",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 14,
        role: "StagePacking",
        menuLink: "/packing",
        menuName: "Scan Packing Production Orders",
    },
];
export const LandingStagePD = [
    {
        roleId: 15,
        role: "StagePD",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 15,
        role: "StagePD",
        menuLink: "/stagePD",
        menuName: "Scan PD Production Orders",
    },
];
export const LandingStageBDC = [
    {
        roleId: 16,
        role: "StageBDC",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 16,
        role: "StageBDC",
        menuLink: "/stageBDC",
        menuName: "Scan BDC Production Orders",
    },
];
export const LandingStagePrinting = [
    {
        roleId: 17,
        role: "StagePrinting",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 17,
        role: "StagePrinting",
        menuLink: "/printing",
        menuName: "Printing Production Orders",
    },
];
export const LandingStageVisual = [
    {
        roleId: 18,
        role: "StageVisual",
        menuLink: "/grnworkflow",
        menuName: "GRN Status",
    },
    {
        roleId: 18,
        role: "StageVisual",
        menuLink: "/visual",
        menuName: "Scan Visual Production Orders",
    },
];
export const MasterRouteLanding = [
    ...LandingAdmin,
    ...LandingSupplier,
    ...LandingLogistics,
    ...LandingStoreGRN,
    ...LandingOutSourcing,
    ...LandingProduction,
    ...LandingQA,
    ...LandingBDC,
    ...LandingStageGRN,
    ...LandingStageBL1,
    ...LandingStageTesting,
    ...LandingStageBL2,
    ...LandingStagePostTesting,
    ...LandingStagePrinting,
    ...LandingStageVisual,
    ...LandingStagePacking,
    ...LandingStagePD,
    ...LandingStageBDC,
];
export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// // For DEV & UAT
// export const baseApiURL = "https://pottsapi-uat.azurewebsites.net/api";

// For Production
export const baseApiURL = "https://pottsprodapi.azurewebsites.net/api";

export function logoutUser() {
    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    window.location.href = "/";
}
const azureKeyVault = "";
const keyVaultValue = "";

export const apiKeys = {
    AzureKeyVault: azureKeyVault,
    KeyVaultValue: keyVaultValue,
};
export const spocEmail = "dilip.dhandar@siemens.com";